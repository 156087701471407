export default function generateImagePath(imageData, login) {
  if (!imageData?.image) return null
  const refPath =
    import.meta.env.VITE_REACT_APP_STATIC === 'static' ? `images` : `/images`
  const baseUrl =
    import.meta.env.VITE_REACT_APP_ENV === 'cd'
      ? `${refPath}`
      : 'https://sites.icasei.com.br/images/thumb/0/1/public'
  const crop = `/${imageData.cropW}/${imageData.cropH}/0/0/0/${imageData.cropX}/${imageData.cropY}/0`
  const cropMobile = `/${imageData.cropW_mobile}/${imageData.cropH_mobile}/0/0/0/${imageData.cropX_mobile}/${imageData.cropY_mobile}/0`
  const loginPath = `/${login[0]}/${login}/imagens/`
  const imagePath = imageData.image

  return {
    background: `${baseUrl}${crop}${loginPath}${imagePath}`,
    backgroundMobile: `${baseUrl}${cropMobile}${loginPath}${imagePath}`,
  }
}
